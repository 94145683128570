import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const HomeContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="content half">
                    <h1 className="heading heading--normal">
                        <strong className="heading--big color--red">Chcesz </strong>
                        więcej klientów?
                    </h1>
                    <p className="para">
                        Skutecznie reklamuj się w internecie wykorzystując potencjał zasobów <strong>Google, Facebook, Alllegro, Instagram, Tiktok</strong> i wiele więcej. Dodaj <strong>zaawansowane strategie</strong>, algorytmy sztucznej inteligencji, <strong> wieloletnie doświadczenie</strong> i zaangażowanie w <strong>Twój biznes</strong>. Działamy?
                    </p>
                    <a href="https://www.google.com/partners/agency?id=8580112023" target="_blank">
                    <StaticImage
                        src="../../images/google_partner.png"
                        width={135}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Google Partner"
                        style={{ marginTop: `30px` }}
                    /></a>
                </div>
                <div className="image half">
                    <StaticImage
                        src="../../images/home_img.png"
                        width={863}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Home images"
                        objectFit="contain"
                    />
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className={`animationIMG imgBox--1`}
            factorX={0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                right: "25%",
                top: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_1.png"
                width={239}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--2`}
            factorX={0.03}
            factorY={-0.04}
            updateStyles={{
                position: "absolute",
                right: "10%",
                top: "0%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_2.png"
                width={234}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--3`}
            factorX={-0.03}
            factorY={0.04}
            updateStyles={{
                position: "absolute",
                right: "30%",
                top: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_3.png"
                width={276}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--4`}
            factorX={-0.05}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                right: "15%",
                top: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_4.png"
                width={270}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--5`}
            factorX={-0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "42%",
                top: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_5.png"
                width={209}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--6`}
            factorX={0.06}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "5%",
                top: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_6.png"
                width={183}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--7`}
            factorX={0.06}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                right: "10%",
                top: "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_7.png"
                width={193}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--8`}
            factorX={-0.06}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                right: "0%",
                top: "35%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_8.png"
                width={187}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--9`}
            factorX={0.02}
            factorY={-0.06}
            updateStyles={{
                position: "absolute",
                right: "48%",
                top: "0%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/IG.png"
                width={208}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className={`animationIMG imgBox--10`}
            factorX={-0.04}
            factorY={-0.04}
            updateStyles={{
                position: "absolute",
                right: "60%",
                top: "75%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/GA.png"
                width={183}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>
)

export default HomeContent
