import * as React from "react"
import { Link } from "gatsby"
const AsideOnePage = ({ activeNav, viewClick }) => {
    // console.log(activeNav)

    return (
        <aside className="aside">
            <nav className="aside__nav">
                <ul>
                    <li>
                        <Link onClick={() => viewClick(true, 'home')} to="#home" className={`${activeNav === 'home' ? "aside__nav--active" : ""}`}>
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'google')} className={`${activeNav === 'google' ? "aside__nav--active" : ""}`} to="#google">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'facebook')} className={`${activeNav === 'facebook' ? "aside__nav--active" : ""}`} to="#facebook">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'pozycjonowanie')} className={`${activeNav === 'pozycjonowanie' ? "aside__nav--active" : ""}`} to="#pozycjonowanie">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'optymalizacja')} className={`${activeNav === 'optymalizacja' ? "aside__nav--active" : ""}`} to="#optymalizacja">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'analityka')} className={`${activeNav === 'analityka' ? "aside__nav--active" : ""}`} to="#analityka">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'case')} className={`${activeNav === 'case' ? "aside__nav--active" : ""}`} to="#case">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'promocja')} className={`${activeNav === 'promocja' ? "aside__nav--active" : ""}`} to="#promocja">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'omnie')} className={`${activeNav === 'omnie' ? "aside__nav--active" : ""}`} to="#omnie">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'formularz')} className={`${activeNav === 'formularz' ? "aside__nav--active" : ""}`} to="#formularz">
                            <span></span>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={() => viewClick(true, 'kontakt')} className={`${activeNav === 'kontakt' ? "aside__nav--active" : ""}`} to="#formularz">
                            <span></span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    )
}
export default AsideOnePage
