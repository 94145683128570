import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const GoogleContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="image half">
                    <StaticImage
                        className="googleAds__img"
                        src="../../images/ga_img.png"
                        width={638}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="usługi google ads"
                        objectFit="contain"
                    />
                </div>
                <div className="content half">
                    <h1 className="heading heading--normal">
                      
                        <span className="color--red">Kampanie Google Ads</span>
                    </h1>
                    <h3><strong>indywidualna strategia · inicjatywa · szybkość działania · skuteczność · raporty live · transparentność · elastyczny model współpracy</strong></h3>
                    <p className="para">
                        Google Ads (dawniej Google AdWords) to reklamy, które obecnie stanowią fundamentalny element marketingu internetowego. Kampanie Google Aads mają wiele możliwości, dzięki czemu skutecznie zastępują lub uzupełniają pozostałe działania reklamowe. Mogą wyświetlać się w wynikach wyszukiwania na wybrane słowa kluczowe, na YouTube i poczcie Gmail, a także na serwisach, które należą do Google Display Network. Google Ads posiada bardzo dużo dedykowanych rozwiązań dla sklepów internetowych.
                    </p>
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className="imgBox--1"
            factorX={-0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                left: "0%",
                top: "12%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_2.png"
                width={234}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--2"
            factorX={0.02}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                left: "28%",
                top: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/GA.png"
                width={190}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--3"
            factorX={0.07}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "20%",
                top: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/ga_icon_2.png"
                width={408}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--4"
            factorX={0.07}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                left: "5%",
                top: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_3.png"
                width={276}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--5"
            factorX={0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "0%",
                top: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/ga_icon_1.png"
                width={190}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>

)

export default GoogleContent
