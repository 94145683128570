import React, { useState } from "react"

import LayoutOnePage from "../components/layoutOnePage"
import Seo from "../components/seo"
// import { motion, useViewportScroll, useTransform, AnimatePresence } from "framer-motion"
import HomeContent from '../components/pageContent/home'
import GoogleContent from '../components/pageContent/google'
import FacebookContent from '../components/pageContent/facebook'
import PozycjonowanieContent from '../components/pageContent/pozycjonowanie'
import OptymalizacjaContent from '../components/pageContent/optymalizacja'
import AnalitykaContent from '../components/pageContent/analityka'
import CaseContent from '../components/pageContent/case'
import PromocjaContent from '../components/pageContent/promocja'
import OmnieContent from '../components/pageContent/omnie'
import FormularzContent from '../components/pageContent/formularz'
import KontaktContent from '../components/pageContent/kontakt'

import { InView } from 'react-intersection-observer'




const OnePagePage = () => {
    const [inViewID, setInView] = useState('home')
    const viewChange = (inView, id) => {
        if (inView) {
            setInView(id);
        }
    }

    // const { scrollYProgress } = useViewportScroll();
    // const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);

    return (
        <LayoutOnePage
            pageName=""
            subNavActive={false}
            inView={inViewID}
            viewClick={viewChange}
        // nextPage="/google/"
        // prevPage="/kontakt/"
        >
            <Seo title="Kampanie Google Ads, Facebook Ads | SEMownia" lang="PL" description="" />
            <InView onChange={(inView) => viewChange(inView, 'home')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="home" className={`home ${inViewID === 'home' ? 'active' : ''} `}>
                        <HomeContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'google')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="google" className={`google ${inViewID === 'google' ? 'active' : ''} `}>
                        <GoogleContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'facebook')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="facebook" className={`facebook ${inViewID === 'facebook' ? 'active' : ''} `}>
                        <FacebookContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'pozycjonowanie')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="pozycjonowanie" className={`pozycjonowanie ${inViewID === 'pozycjonowanie' ? 'active' : ''} `}>
                        <PozycjonowanieContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'optymalizacja')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="optymalizacja" className={`optymalizacja ${inViewID === 'optymalizacja' ? 'active' : ''} `}>
                        <OptymalizacjaContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'analityka')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="analityka" className={`analityka ${inViewID === 'analityka' ? 'active' : ''} `}>
                        <AnalitykaContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'case')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="case" className={`case ${inViewID === 'case' ? 'active' : ''} `}>
                        <CaseContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'promocja')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="promocja" className={`promocja ${inViewID === 'promocja' ? 'active' : ''} `}>
                        <PromocjaContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'omnie')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="omnie" className={`about ${inViewID === 'omnie' ? 'active' : ''} `}>
                        <OmnieContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'formularz')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="formularz" className={`form ${inViewID === 'formularz' ? 'active' : ''} `}>
                        <FormularzContent />
                    </section>
                )}
            </InView>
            <InView onChange={(inView) => viewChange(inView, 'kontakt')}>
                {({ ref }) => (
                    <section ref={ref}
                        id="kontakt" className={`contact ${inViewID === 'kontakt' ? 'active' : ''} `}>
                        <KontaktContent />
                    </section>
                )}
            </InView>

        </LayoutOnePage>
    )
}

export default OnePagePage
