import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const AnalitykaContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="image half">
                    <StaticImage
                        className="facebookAds__img"
                        src="../../images/an_img.png"
                        width={449}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="usługi facebook ads"
                        objectFit="contain"
                    />
                </div>
                <div className="content half">
                    <h1 className="heading heading--normal">
                   
                        <span className="color--red"> Analityka Internetowa</span>
                    </h1>
                    <p className="para">
                        Analityka jest podstawą do tego, aby kierować działania marketingowe w odpowiednim kierunku. Zbieranie mierzalnych danych, porównywanie ich i weryfikacja pozwalają na to, aby odpowiedzieć na najważniejsze pytania. Doskonale wiem jak wiele praktycznych informacji przynosi analityka i ciągłe mierzenie efektywności bieżących działań. Dlatego też dzięki profesjonalnym narzędziom porównuje i zestawiam dane, a na ich podstawie tworzę strategie dopasowane do Twoich potrzeb.
                    </p>
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className="imgBox--1"
            factorX={-0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                left: "25%",
                top: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/an_icon_2.png"
                width={227}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--2"
            factorX={0.02}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                left: "35%",
                top: "65%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/an_icon_1.png"
                width={215}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--3"
            factorX={0.07}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "8%",
                top: "45%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/an_icon_5.png"
                width={229}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--4"
            factorX={-0.07}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                left: "0%",
                top: "58%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/an_icon_4.png"
                width={165}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--5"
            factorX={0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "0%",
                top: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/an_icon_3.png"
                width={227}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>

)

export default AnalitykaContent
