import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const OptymalizacjaContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="content half">
                    <h1 className="heading heading--normal">
                      
                        <span className="color--red"> Optymalizacja Konwersji</span>
                    </h1>
                    <p className="para">
                        Optymalizacja konwersji bezpośrednio przekłada się na zamierzenia biznesowe. Jej skutkiem jest osiągnięcie przez daną witrynę konkretnych celów. Dbanie o pozycjonowanie jest podstawą, bo to ono sprawia, że Twoja strona jest widoczna i trafia na nią więcej odbiorców. Jednak to właśnie optymalizacja konwersji prowadzi do tego, że użytkownicy wykonują działania, na których najbardziej Ci zależy.
                    </p>
                </div>
                <div className="image half">
                    <StaticImage
                        className="facebookAds__img"
                        src="../../images/op_img.png"
                        width={592}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="usługi facebook ads"
                        objectFit="contain"
                    />
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className="imgBox--1"
            factorX={-0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                right: "35%",
                top: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_3.png"
                width={276}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--2"
            factorX={0.02}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                right: "0%",
                top: "18%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/op_icon_3.png"
                width={193}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--3"
            factorX={0.07}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "18%",
                top: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/op_icon_1.png"
                width={245}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--4"
            factorX={-0.07}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                right: "5%",
                top: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/op_icon_2.png"
                width={211}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--5"
            factorX={0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "38%",
                top: "58%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_1.png"
                width={239}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>

)

export default OptymalizacjaContent
