import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import "swiper/css"
import "../../style/page/case.scss"

const CaseContent = () => (

    <div className="container">
        <h1 className="heading heading--normal">Wybrane Case Study</h1>
    <p className="para">i wiele innych o których chętnie Ci opowiem</p>
        <Swiper
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            centeredSlides={false}
            loop={true}
            slidesPerView={1}
            spaceBetween={10}
            modules={[Autoplay]}
            breakpoints={{
                // 460: {
                //   slidesPerView: 2
                // },
                650: {
                    spaceBetween: 20,
                    slidesPerView: 2
                },
                900: {
                    spaceBetween: 20,
                    slidesPerView: 3
                },
                1200: {
                    spaceBetween: 65,
                    slidesPerView: 3
                }
            }}
        >
            <SwiperSlide>
                <div className="case__item">
                    <StaticImage
                        src="../../images/case_1.png"
                        width={480}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Home images"
                    />
                    <p className="case__heading">
                        PRODUCENT
                        <br /> SUPLEMENTÓW
                    </p>
                    <div className="case__description">
                        <p>Wzrost przychodów: +219%</p>
                        <p>Koszt konwersji: -62%</p>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="case__item">
                    <StaticImage
                        src="../../images/case_2.png"
                        width={480}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Home images"
                    />
                    <p className="case__heading">
                        SKLEP
                        <br /> MEBLOWY
                    </p>
                    <div className="case__description">
                        <p>Wzrost przychodów: +610%</p>
                        <p>Koszt konwersji: -24%</p>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="case__item">
                    <StaticImage
                        src="../../images/case_3.png"
                        width={480}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Home images"
                    />
                    <p className="case__heading">
                        SKLEP
                        <br />DLA DZIECI
                    </p>
                    <div className="case__description">
                        <p>Wzrost przychodów: +610%</p>
                        <p>ROAS= 1180%</p>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>

)

export default CaseContent
