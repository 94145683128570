import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const FacebookContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="content half">
                    <h1 className="heading heading--normal">
    
                        <span className="color--red"> Facebook Ads</span>
                    </h1>
                    <h3><strong>Maksymalizacja ROAS · inicjatywa · indywidualne grafiki · integracja Pixela· transparentna współpraca</strong></h3>
                    <p className="para">
                        Kampanie Facebook Ads oraz na pozostałych platform social media (Instagram, WhatsUp, LinkedIn, TikTok) to pewność, że żaden istotny element podczas planowania strategii nie zostanie pominięty. Ustalę odpowiednią grupę docelową, przeanalizujemy cel i model biznesowy Twojej firmy, a także określimy niezbędny budżet marketingowy dla zrealizowania obranego celu. Najistotniejsza jest jednak kreacja – porządany przekaz, oprawa graficzna i treść, która oddziaływuje nawet najbardziej niezdecydowanych. Tego właśnie potrzebuje Twoja marka!
                    </p>
                </div>
                <div className="image half">
                    <StaticImage
                        loading="lazy"
                        className="facebookAds__img"
                        src="../../images/fa_img.png"
                        width={485}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="usługi facebook ads"
                        objectFit="contain"
                    />
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className="imgBox--1"
            factorX={-0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                right: "40%",
                top: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/fa_icon_1.png"
                width={190}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--2"
            factorX={0.02}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                right: "5%",
                top: "12%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_7.png"
                width={193}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--3"
            factorX={0.07}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "28%",
                top: "42%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_8.png"
                width={188}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--4"
            factorX={-0.07}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                right: "38%",
                top: "58%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/IG.png"
                width={234}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--5"
            factorX={0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                right: "5%",
                top: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_4.png"
                width={240}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>

)

export default FacebookContent
