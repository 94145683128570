import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { useForm } from "react-hook-form"
import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse"
import "../../style/page/form.scss"

const FormularzContent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [sendingPass, setsendingPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const onSubmit = (values, e) => {
    const message = document.querySelector("textarea").value
    const formData = new FormData()
    formData.append("name", values.name)
    formData.append("email", values.email)
    formData.append("www", values.www)
    formData.append("phone", values.phone)
    formData.append("message", message)
    formData.append("checkboxOne", values.RODO)
    setLoading(true)
    return fetch("/send/send.php/", {
      method: "post",
      body: formData,
    }).then(response => {
      if (response.status === 200) {
        setLoading(false)
        setsendingPass(true)
        e.target.reset()
      }
    })
  }
  return (
    <MouseParallaxContainer
      className="parallax"
      containerStyles={{
        width: "100%",
        overflow: "initial",
      }}
      resetOnLeave
    >
      <div className="container">
        <div className="row jc-sb ai-center">
          <div className="content half">
            <h1 className="heading heading--normal">
              Wybierz
              <span className="color--red"> Profesjonalistę</span>
            </h1>
            <p className="para">
              <strong>
                Porozmawiajmy o Twoich celach –
                wypełnij formularz skontaktuję się natychmiast. Wolisz zadzwonić i porozmawiać <a href="tel:+48792250586"> +48 792 250 586</a>
              </strong>
            </p>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form__row">
                <div className="form__half">
                  <input
                    placeholder="Imię Nazwisko / Nazwa Firmy"
                    type="text"
                    {...register("name", {
                      required: true,
                    })}
                  />
                  <span>{errors.name && "Zapomiałeś o tym polu"}</span>
                </div>
                <div className="form__half">
                  <input
                    placeholder="Telefon"
                    type="tel"
                    {...register("phone", {
                      required: true,
                    })}
                  />
                  <span>{errors.phone && "Zapomiałeś o tym polu"}</span>
                </div>
              </div>
              <div className="form__row">
                <div className="form__half">
                  <input
                    placeholder="Adres Email"
                    type="email"
                    {...register("email", {})}
                  />
                  <span>{errors.email && "Zapomiałeś o tym polu"}</span>
                </div>
                <div className="form__half">
                  <input
                    placeholder="Adres Strony www"
                    type="text"
                    {...register("www", {})}
                  />
                  <span>{errors.www && "Zapomiałeś o tym polu"}</span>
                </div>
              </div>
              <div className="form__row">
                <textarea
                  placeholder="Opis projektu"
                  {...register("description", {})}
                />
              </div>
              <div className="form__row form__rodo">
                <label class="">
                  <input
                    type="checkbox"
                    {...register("RODO", {
                      required: true,
                    })}
                  />
                  <span class="form__rodo__text">
                    Wyrażam zgodę na przetwarzanie przez SEMownia Szymon
                    Sanecznik z siedzibą w Warszawie podanych wyżej danych
                    osobowych do celów przedstawienia przez Firmę jej oferty
                    handlowej, prowadzenia negocjacji handlowych, ewentualnie
                    późniejszego zawarcia i realizacji umowy o świadczenie
                    usług przez Firmę. Podanie danych jest dobrowolne, ale
                    niezbędne do przetworzenia zapytania.
                  </span>
                </label>
                <span class="form__rodo__error">
                  {errors.RODO && "Nie zaakceptowałeś zgody"}
                </span>
              </div>
              <div className="form__row">
                <button type="submit">Wyślij</button>
                {loading ? <div className="form__load">wysyłanie</div> : ""}
                <div className="form__ok">
                  {sendingPass ? (
                    <p class="thx">Wiadomość wysłana poprawnie</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="image half">
            <StaticImage
              className="facebookAds__img"
              src="../../images/contact_img.png"
              width={979}
              quality={100}
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="usługi facebook ads"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
      <MouseParallaxChild
        className="imgBox--1"
        factorX={-0.05}
        factorY={0.05}
        updateStyles={{
          position: "absolute",
          right: "5%",
          top: "10%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StaticImage
          src="../../images/contact_icon_4.png"
          width={226}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Home images"
        />
      </MouseParallaxChild>
      <MouseParallaxChild
        className="imgBox--2"
        factorX={0.02}
        factorY={0.02}
        updateStyles={{
          position: "absolute",
          right: "10%",
          top: "60%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StaticImage
          src="../../images/contact_icon_2.png"
          width={225}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Home images"
        />
      </MouseParallaxChild>
      <MouseParallaxChild
        className="imgBox--3"
        factorX={0.07}
        factorY={-0.05}
        updateStyles={{
          position: "absolute",
          right: "35%",
          top: "65%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StaticImage
          src="../../images/contact_icon_1.png"
          width={226}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Home images"
        />
      </MouseParallaxChild>
      <MouseParallaxChild
        className="imgBox--4"
        factorX={-0.07}
        factorY={-0.02}
        updateStyles={{
          position: "absolute",
          right: "35%",
          top: "20%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StaticImage
          src="../../images/contact_icon_3.png"
          width={227}
          quality={100}
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
          alt="Home images"
        />
      </MouseParallaxChild>
    </MouseParallaxContainer>

  )
}

export default FormularzContent
