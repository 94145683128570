import * as React from "react"
import { Link } from "gatsby"
import NavArrow from "../images/nav_arrow.svg"

const FooterOnePage = ({ subNavActive, viewClick, activeNav }) => {
    let activeSubNav = false;
    switch (activeNav) {
        case 'google':
            activeSubNav = true;
            break;
        case 'facebook':
            activeSubNav = true;
            break;
        case 'pozycjonowanie':
            activeSubNav = true;
            break;
        case 'optymalizacja':
            activeSubNav = true;
            break;
        case 'analityka':
            activeSubNav = true;
            break;
        default:
            break;
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="row jc-sb ai-center">
                    <nav className="footer__nav">
                        <ul className="row jc-sb ">
                            <li className="footer__hasSubNav">
                                <span

                                    className={`${activeSubNav ? "footer__nav--active" : ""}`}
                                >
                                    Usługi
                                    <NavArrow />
                                </span>
                                <ul className="row jc-sb footer__subNav">
                                    <li className="footer__nav--google">
                                        <Link onClick={() => viewClick(true, 'google')} to="#google" className={`${activeNav === 'google' ? "footer__nav--active" : ""}`}>
                                            Google
                                            <br /> ADS
                                        </Link>
                                    </li>
                                    <li className="footer__nav--facebook">
                                        <Link onClick={() => viewClick(true, 'facebook')} to="#facebook" className={`${activeNav === 'facebook' ? "footer__nav--active" : ""}`}>
                                            FACEBOOK
                                            <br /> ADS
                                        </Link>
                                    </li>
                                    <li className="footer__nav--position">
                                        <Link onClick={() => viewClick(true, 'pozycjonowanie')} to="#pozycjonowanie" className={`${activeNav === 'pozycjonowanie' ? "footer__nav--active" : ""}`}>
                                            POZYCJONOWANIE
                                        </Link>
                                    </li>
                                    <li className="footer__nav--optymilize">
                                        <Link onClick={() => viewClick(true, 'optymalizacja')} to="#optymalizacja" className={`${activeNav === 'optymalizacja' ? "footer__nav--active" : ""}`}>
                                            OPTYMALIZACJA <br />
                                            KONWERSJI
                                        </Link>
                                    </li>
                                    <li className="footer__nav--analyse">
                                        <Link onClick={() => viewClick(true, 'analityka')} to="#analityka" className={`${activeNav === 'analityka' ? "footer__nav--active" : ""}`}>
                                            ANALITYKA
                                            <br /> INTERNETOWA
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link onClick={() => viewClick(true, 'case')} to="#case" className={`${activeNav === 'case' ? "footer__nav--active" : ""}`}>
                                    Case Study
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => viewClick(true, 'promocja')} to="#promocja" className={`${activeNav === 'promocja' ? "footer__nav--active" : ""}`}>
                                    Promocja
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => viewClick(true, 'omnie')} to="#omnie" className={`${activeNav === 'omnie' ? "footer__nav--active" : ""}`}>
                                    O mnie
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="footer__contact">
                        <Link onClick={() => viewClick(true, 'kontakt')} to="#formularz" className={`${activeNav === 'kontakt' ? "footer__contact--active" : ""}`}>
                            Kontakt
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterOnePage
