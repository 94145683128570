import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


const PromocjaContent = () => (
    <div className="container">
        <div className="row jc-sb ai-center">
            <div className="image half">
                <StaticImage
                    className="facebookAds__img"
                    src="../../images/pormo_img.png"
                    width={810}
                    quality={100}
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="usługi facebook ads"
                    objectFit="contain"
                />
            </div>
            <div className="content half">
                <h1 className="heading heading--normal">
                    Promocja
                    <br />{" "}
                    <span className="color--red">
                        Google Ads &<br /> Facebook Ads
                    </span>
                    = Synergia!
                </h1>
            </div>
        </div>
    </div>

)

export default PromocjaContent
