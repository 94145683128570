import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from "react-parallax-mouse"

const PozycjonowanieContent = () => (

    <MouseParallaxContainer
        className="parallax"
        containerStyles={{
            width: "100%",
            overflow: "initial",
        }}
        resetOnLeave
    >
        <div className="container">
            <div className="row jc-sb ai-center">
                <div className="image half">
                    <StaticImage
                        className="facebookAds__img"
                        src="../../images/poz_img.png"
                        width={908}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="usługi facebook ads"
                        objectFit="contain"
                    />
                </div>
                <div className="content half">
                    <h1 className="heading heading--normal">
                     
                        <span className="color--red"> Pozycjonowanie</span>
                    </h1>
                    <p className="para">
                        Pozycjonowanie stron internetowych jest procesem, którego główny cel polega na zwiększeniu widoczności domeny w bezpłatnych (organicznych) wynikach wyszukiwania. Działania te polegają na optymalizacji wszystkich elementów witryny oraz budowania zaplecza backlinków (link building). Na pozycjonowanie składają się liczne procesy dotyczące działań SEO, które przeprowadza się on-site (na stronie) oraz off-site (poza stroną). Chętnie pomagam opracować konsekwencję oraz poszczególne procesy rozwoju pozycji w wyszukiwarce.
                    </p>
                </div>
            </div>
        </div>
        <MouseParallaxChild
            className="imgBox--1"
            factorX={-0.05}
            factorY={0.05}
            updateStyles={{
                position: "absolute",
                left: "10%",
                top: "-15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/home_icon_2.png"
                width={234}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--2"
            factorX={0.02}
            factorY={0.02}
            updateStyles={{
                position: "absolute",
                left: "30%",
                top: "-10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/poz_icon_2.png"
                width={202}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--3"
            factorX={0.07}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "30%",
                top: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/poz_icon_4.png"
                width={242}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--4"
            factorX={-0.07}
            factorY={-0.02}
            updateStyles={{
                position: "absolute",
                left: "10%",
                top: "58%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/poz_icon_3.png"
                width={205}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
        <MouseParallaxChild
            className="imgBox--5"
            factorX={0.02}
            factorY={-0.05}
            updateStyles={{
                position: "absolute",
                left: "-2%",
                top: "0%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <StaticImage
                src="../../images/poz_icon_1.png"
                width={230}
                quality={100}
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
                alt="Home images"
            />
        </MouseParallaxChild>
    </MouseParallaxContainer>

)

export default PozycjonowanieContent
