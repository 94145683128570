import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Grid, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import "../../style/page/about.scss"

const OmnieContent = () => (
    <div className="container">
        <div className="row jc-sb">
            <div className="content half">
                <h1 className="heading heading--normal">O Mnie</h1>
                <div className="about__img">
                    <StaticImage
                        src="../../images/AVATAR.png"
                        width={144}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        objectFit="contain"
                    />
                    <p>
                        Szymon
                        <br /> Sanecznik
                    </p>
                </div>
                <p className="para">
                    Od 2011 roku zajmuję się tematyką marketingu internetowego.
                    Specjalizuję się w obszarze SEM. Pasjonuję się liczbami, analityką
                    internetową i optymalizacją konwersji. Szlifowałem fach w
                    najlepszych polskich agencjach SEO/SEM pracując między innymi dla:
                </p>
                <div className="about__images">
                    <StaticImage
                        src="../../images/plus.png"
                        width={86}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        objectFit="contain"
                    />
                    <StaticImage
                        src="../../images/azoty.png"
                        width={92}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        objectFit="contain"
                    />
                    <StaticImage
                        src="../../images/alianz.png"
                        width={94}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        objectFit="contain"
                    />
                    <StaticImage
                        src="../../images/mcdonald.png"
                        width={98}
                        quality={100}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt=""
                        objectFit="contain"
                    />
                </div>
            </div>
            <div className="content half">
                <h2 className="heading heading--normal">
                    Firmy
                    <span className="color--red"> które mi zaufały</span>
                </h2>
                <div className="slider">
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={3}
                        grid={{
                            rows: 2,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        spaceBetween={20}
                        modules={[Grid, Autoplay, Pagination]}
                    >
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/sr.png"
                                width={225}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/cormak.png"
                                width={225}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/ff.png"
                                width={119}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/onde.png"
                                width={225}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/rafako.png"
                                width={225}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/gardenowo.png"
                                width={119}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/babylove.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/leterus.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StaticImage
                                src="../../images/wesolebuciki.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
   <SwiperSlide>
                            <StaticImage
                                src="../../images/sukcesja.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
   <SwiperSlide>
                            <StaticImage
                                src="../../images/partnerstal.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
   <SwiperSlide>
                            <StaticImage
                                src="../../images/camport.png"
                                width={98}
                                quality={100}
                                placeholder="blurred"
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                objectFit="contain"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
)

export default OmnieContent
