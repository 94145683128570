/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import * as React from "react"
import React, { useEffect, useState } from "react"
// import {  } from "gatsby-link"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import FooterOnePage from "./footerOnePage"
import "../style/style.scss"
import AsideOnePage from "./asideOnePage"
// import CookieConsent from "react-cookie-consent"
import { Helmet } from "react-helmet"




const LayoutOnePage = ({
  children,
  subNavActive,
  pageName,
  inView,
  viewClick
}) => {
  // console.log(inView)
  const [navOpen, setNavOpen] = useState(false)

  const toggleNav = () => {
    setNavOpen(!navOpen);
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let action = true;
      const viewportWidth = window.innerWidth;

      var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

      function preventDefault(e) {
        const delta = e.deltaY;
        e.preventDefault();
        // console.log(this)
        scrollToSection(delta);
      }

      function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
          preventDefault(e);
          return false;
        }
      }
      function scrollToSection(delta) {
        const sections = document.querySelectorAll('.onePage section');
        let activeSection;
        let nextSection;
        let indexCurrentSection = 0;
        let indexNextSection = 0;
        for (var i = 0; i < sections.length; i++) {
          var item = sections[i];
          if (item.classList.contains('active')) {
            indexCurrentSection = i;
          }
        }
        if (action) {
          if (indexCurrentSection === 0) {
            if (delta > 0) {
              indexNextSection = indexCurrentSection + 1;
            }
            else {
              indexNextSection = sections.length - 1;
            }
          }
          else if (indexCurrentSection < (sections.length - 1)) {
            if (delta > 0) {
              indexNextSection = indexCurrentSection + 1;

            }
            if (delta < 0) {
              indexNextSection = indexCurrentSection - 1;
            }
          }
          else {
            if (delta > 0) {
              indexNextSection = 0;

            }
            if (delta < 0) {
              indexNextSection = indexCurrentSection - 1;
            }
          }
          setTimeout(() => {
            action = true;
          }, 500);
          nextSection = sections[indexNextSection];
          nextSection.scrollIntoView();
        }
        action = false;
      }

      // modern Chrome requires { passive: false } when adding event
      var supportsPassive = false;
      try {
        window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
          get: function () { supportsPassive = true; }
        }));
      } catch (e) { }

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

      function disableScroll() {
        window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      if (viewportWidth > 1060) {
        disableScroll()
      }
    }
  }, [])


  const data = useStaticQuery(graphql`
    query SiteTitleQueryOnePage {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <noscript>
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMDPV35"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      <Helmet>
        <script type='text/javascript' ata-rh="true" data-react-helmet="true" >
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MMDPV35');`}
        </script>
      </Helmet>
      <Header viewClick={viewClick} activeNav={inView} navOpen={navOpen} toggleNav={toggleNav} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className={`main container onePage ${pageName}`}>
        {children}
        <AsideOnePage viewClick={viewClick} activeNav={inView} />
      </main>

      <FooterOnePage viewClick={viewClick} activeNav={inView} subNavActive={subNavActive} />
      {/* <CookieConsent
                enableDeclineButton
                location="bottom"
                buttonText="Akceptuję"
                declineButtonText="Wyłącz"
                cookieName="gatsby-gdpr-google-tagmanager"
                // style={{ background: "#2B373B" }}
                // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                // declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={365}
            >
                Używamy plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny.
            </CookieConsent> */}
    </>
  )
}

LayoutOnePage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutOnePage
