import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../../style/page/contact.scss"

const KontaktContent = () => (

    <div className="container">
        <div className="row jc-sb ai-center">
            <div className="iframe half">
                <iframe
                    ttile="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22651.11976804935!2d20.981512293094426!3d52.23241250868937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc8e4a989883%3A0xef21d88a63753f3a!2sTwarda%2044%2C%2000-831%20Warszawa!5e0!3m2!1spl!2spl!4v1645561844437!5m2!1spl!2spl"
                    width="600"
                    height="518"
                    style={{ border: 0, borderRadius: "16px" }}
                    allowfullscreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <div className="half">
                <h1 className="heading heading--normal">Kontakt</h1>
                <div className="contact__wrapper">
                    <div className="contact__box">
                        <p>
                            <strong>Adres</strong>
                        </p>
                        <p>ul. Twarda 44</p>
                        <p>00-831 Warszawa</p>
                    </div>
                    <div className="contact__box">
                        <p>
                            <strong>Kontakt</strong>
                        </p>
                        <p>Tel: <a href="tel:792250586">+48 792 250 586</a></p>
                        <p>Email: <a href="mailto:biuro@semownia.pl">biuro@semownia.pl</a></p>
                    </div>
                    <div className="contact__box">
                        <p>
                            <strong>Firma</strong>
                        </p>
                        <p>NIP: 642-293-16-98</p>
                        <p>REGON: 24131981</p>
                    </div>
                    <div className="contact__box">
                        <p>
                            <strong>Konto Bankowe</strong>
                        </p>
                        <p>mBank nr: 47 1140 2004 0000 3202 6415 7094</p>
                    </div>
                    <div className="contact__box">
                        <p>
                            <strong>Godziny</strong>
                        </p>
                        <p>Pon-Pt – 9:00 – 17:00</p>
                        <p>Sob-Ndz – Nieczynne</p>
                    </div>
                    <div className="contact__box">
                        <p>
                            <strong>Specjalizacja</strong>
                        </p><a href="https://www.google.com/partners/agency?id=8580112023" target="_blank">
                        <StaticImage
                            src="../../images/google-partner_kontakt.png"
                            width={176}
                            quality={100}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            objectFit="contain"
                        /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default KontaktContent
